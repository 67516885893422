import React from "react"
import Layout from "components/Layout"
import Hero from "components/Layout/Hero"
import Products from "components/Products"
import Testimonials from "components/Testimonials"
import BlogPosts from "components/BlogPosts"
import Seo from "components/SEO"

const index = () => {
  return (
    <React.Fragment>
      <Seo
        title="High Quality Live Mealworms for Pets | Welcome to Mandatory Insects"
        description="High Quality Live Mealworms
With high nutritional properties and fibre. Mealworms are perfect to feed reptiles , birds, small rodents and fish"
      />
      <Layout>
        <Hero />
        <Products />
        <Testimonials />
        <BlogPosts />
        <div></div>
      </Layout>
    </React.Fragment>
  )
}

export default index
