import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Hero = () => {
  return (
    <React.Fragment>
      <section className="max-w-8xl mx-auto container pt-16 bg-white">
        <div className="flex flex-col md:flex-row">
          <div className="pb-5 flex flex-col w-full lg:w-6/12 px-4 md:px-8 justify-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">High Quality</span>{" "}
              <span className="block text-lime-600 xl:inline">
                Live Insects
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              With their high nutritional properties. Our insects are perfect
              choice to feed your reptiles, birds, chicken and fish.
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <Link
                  to="/shop/"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 md:py-4 md:text-lg "
                >
                  Buy Now
                </Link>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <Link
                  to="/blog/"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-lime-700 bg-lime-100 hover:bg-lime-200 md:py-4 md:text-lg "
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:w-6/12 md:w-1/2 relative mx-4  lg:ml-20  pt-2 pb-2 ">
            <StaticImage
              imgClassName="mt-16 pr-20 relative z-10 rounded w-full"
              src="../../images/website/reptiles.png"
              alt="Reptiles"
              placeholder="tracedSVG"
              layout="fullWidth"
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Hero
